<template>
    <div v-if="accountData">
        <div class="bg-white mb-3">
            <div class="d-flex justify-content-between align-items-center">
                <div>
                    <h1 class="h4 mb-0">{{ accountData.code }} - {{accountData.dentist.first_name}} {{accountData.dentist.surname}} at {{accountData.practice.company_name}}</h1>
                </div>
            </div>
        </div>
        <ul class="nav nav-tabs">
            <li class="nav-item">
                <router-link :to="{ name: 'Account', params: { account_id: accountData.id }}" class="nav-link">Transactions</router-link>
            </li>
            <li class="nav-item">
                <router-link :to="{ name: 'Statements', params: { account_id: accountData.id }}" class="nav-link">Statements</router-link>
            </li>
            <li class="nav-item">
                <router-link :to="{ name: 'AccountExports', params: { account_id: accountData.id }}" class="nav-link active">Exports</router-link>
            </li>
        </ul>
        <div class="bg-white pt-4">
            <table v-if="exportsData" class="exports-table">
                <thead>
                    <tr>
                        <th class="column-shrink">Date Created</th>
                        <th class="column-shrink">Type</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody v-for="(bulkExport, bulkExportIndex) of exportsData.data" v-bind:key="bulkExportIndex" class="mb-1">
                    <tr>
                        <td>
                            {{bulkExport.datetime_created}}
                        </td>
                        <td>
                            {{bulkExport.type}}
                        </td>
                        <td>
                            {{bulkExport.start_date}}
                        </td>
                        <td>
                            {{bulkExport.end_date}}
                        </td>
                        <td>
                            {{bulkExport.status}}
                        </td>
                        <td>
                            <a :href=bulkExport.download_url class="btn btn-primary" target="_blank" v-if="bulkExport.status=='Completed'">
                                Download
                            </a>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div v-if="exportsData && !exportsData.data.length">
                No Bulk Exports Found
            </div>
            <Pagination
                v-if="exportsData && exportsData.data.length"
                v-bind:currentPage="currentPage"
                v-bind:lastPage="lastPage"
                viewComponent="this.routeName"
            />
        </div>
    </div>
</template>

<style scoped lang="scss">
    .exports-table
    {
        border-collapse: collapse;
        width: 100%;
        line-height: 1em;

        &.product-table-collapsed
        {
            width: auto;
        }
        
        td
        {
            border-top: 1px solid #EEEEEE;
        }

        td,
        th
        {
            vertical-align: middle;
            padding: 10px 0.75em;

            &:first-child
            {
                padding-left: 0px;
            }

            &:last-child
            {
                padding-right: 0px;
            }
        }

        .column-shrink
        {
            width: 0px;
            white-space: nowrap;
        }

        .column-number
        {
            width: 0px;
            white-space: nowrap;
        }

        td.column-number
        {
            text-align: right;
        }
    }
</style>

<script>
    import api from "@/services/api";
    import Pagination from "@/components/Pagination.vue";

    export default {
        props: {
            id: {
                required: true
            }
        },
        components: {
            Pagination
        },
        data() {
            return {
                exportsData: null,
                accountData: null            
            }
        },        
        mounted () {
            this.fetchAccount();
            this.fetchExports();
            this.fetchTypes();            
        },
        computed: {
            currentPage () {
                var pageParam = this.$route.query.page;
                return typeof pageParam === 'undefined' ? 1 : parseInt(pageParam);
            },
            lastPage () {
                if(this.exportsData !== null)
                {
                    return parseInt(this.exportsData.meta.last_page);
                }

                return null;
            },
        },
        watch: {
            currentPage() {
                this.fetchExports();
            },
            '$store.state.currentPracticeId': function() {
                this.fetchExports();
            }
        },
        methods: {
            fetchAccount: function() {                
                api
                .get('/api/v1/accounts/' + this.id)
                .then((response) => {
                    this.accountData = response.data.data;
                    this.fetchExports();
                })
                .catch(() => {
                    console.log('Catch account error');
                });
            },
            fetchExports: function() {  
                var params = {
                    page: this.currentPage,
                };

                api
                .get('/api/v1/accounts/' + this.id + '/bulkexports', {
                    params: params,
                })
                .then((response) => {
                    this.exportsData = response.data;
                    console.log(response.data)
                })
                .catch(() => {
                    console.log('Catch exports error');
                });
            },
            fetchTypes: function() {                
                this.types = [{
                    id: 1,
                    label: 'Invoice',
                }, {
                    id: 2,
                    label: 'Patient Statement',
                }]
            },
            submitFilter: async function()
            {
                var isValid = await this.v$.$validate();

                if(isValid)
                {
                    this.fetchExports();
                }
                else
                {
                    console.log('Validation errors', this.v$.$errors.map((error) => { return error.$property + ': ' + error.$message}));
                }

                return false;
            },
        },
    }
</script>